import React from 'react'
import PropTypes from 'prop-types'

import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

import img1 from '../images/img1.jpg'
import img2 from '../images/img2.jpg'
import img3 from '../images/img3.jpg'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="intro" className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Grafhouseについて</h2>
          <span className="image main"><img src={img1} alt="" /></span>
           <p>Grafhouseはアカウント不要のお絵描きSNSです。投稿はすべて匿名で行われます。手書き画像のみを投稿することが可能です。投稿された画像に編集を加えて再投稿することができます。</p>
           <p>コラボレーションを繰り返して素晴らしい落書き(Graffiti)が生まれることを期待します。</p>
          {close}
        </article>

        <article id="work" className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">著作権と利用規約</h2>
          <span className="image main"><img src={img2} alt="" /></span>
          <p>著作権</p>
          <p>下記の条件を受け入れていただけるのであれば、誰でも自由に無料で、このソフトウェアをつかっていただくことができます。</p>
          <p>アプリ内に投稿したイラスト及びメッセージの権利を全て放棄してください。</p>
          <p>ユーザーは、自ら著作権等の必要な知的財産権を有するか、または必要な権利者の許諾を得た文章、画像や映像等の情報に関してのみ、本サービスを利用し、投稿ないしアップロードすることができるものとします。</p>
          <p>アプリ内に投稿されたイラスト及びメッセージはコピーしてつかったり、配布したり、変更を加えたり、変更を加えたものを配布したり、商用利用したり、有料で販売したり、なんにでも自由につかってください。</p>
          <p>このソフトウェアにはなんの保証もついていません。たとえ、このソフトウェアを利用したことでなにか問題が起こったとしても、作者はなんの責任も負いません。</p>
          <p> </p>
          <p>利用規約</p>
          <p>Grafhouse は、主にアプリユーザーの情報共有・交流・コラボレーションを目的とするコミュニティです。</p>
          <p>我々(Grafhouse運営)はすべての参加者がそのコミュニティから歓迎され、楽しんで頂くことをめざしています。</p>
          <p>そのためには、Grafhouse関連コミュニティにおける主催者やスタッフ、出展者、観客などのすべての参加者の皆様は、本行動規範に則って他の参加者に対して敬意と礼儀をもってコミュニティに参加いただくようにお願いいたします。</p>
          <p>Grafhouse関連コミュニティでは全ての人を歓迎します。</p>
          <p>そのために、他の参加者が不快に感じる行為はそれがどのような形であっても許容いたしません。</p>
          <p>参加者の皆さまは他の参加者の様々な背景に配慮してください。あなたの何気ない投稿や行動で悲しむ人がいるかもしれません。注意深く、優しく振る舞ってください。</p>
          <p>我々運営は参加者の皆さまの権利を守るため、規範に反する方へ注意だけではなくコミュニティから退場させたり、場合によっては法的機関への相談など、適切と思われる対応をおこないます。</p>
          <p>もしあなた、ないしは他の参加者がハラスメント行為を受けた場合、お近くのGrafhouseスタッフにご相談いただくか、アプリに明記された連絡窓口にご連絡ください。</p>
          <p>お知らせ頂いた内容は法的な情報開示の必要性がある場合を除いて、あなたの同意がないかぎり匿名で扱いますのでご安心ください。</p>
          {close}
        </article>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">プライバシーポリシー</h2>
          <span className="image main"><img src={img3} alt="" /></span>
          <p>Grafhouseは一切のユーザー情報を取得しません</p>
          <p>ユーザーの個人情報には1ミリも興味がありません。これまでも、そしてこれからも。</p>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">連絡先</h2>
          <p>アプリについてのご意見ご感想は作者のツイッターか下記メールアドレスにお寄せください。多くのフィードバックをお待ちしております。</p>
          <p className="icon fa-mail-forward"> grafhouse.staff@gmail.com</p>
          <ul className="icons">
            <li><a href="https://twitter.com/votepurchase" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
          </ul>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main