import React from 'react'
import PropTypes from 'prop-types'
import astore from '../assets/app-store-badge.svg';
import gplay from '../assets/google-play-badge.svg'

const Header = (props) => (
	<header id="header" style={props.timeout ? {display: 'none'} : {}}>
		<div className="logo">
			<span className="icon fa-paint-brush"></span>
		</div>
		<div className="content">
			<div className="inner">
				<h1>Grafhouse</h1>
				<p>Grafhouse(Graffiti House)は落書きのコラボレーションを目的としたお絵描きSNSアプリです</p>
				<div className="row">
					<div className="badges">
						<a className="badge-link" target="_blank" href="https://play.google.com/store/apps/details?id=net.votepurchase.grafhouse">
							<img src={gplay} alt="" />
						</a>
						<a className="badge-link" target="_blank" href="https://apps.apple.com/jp/app/grafhouse/id1554180611">
							<img src={astore} alt="" />
						</a>
          </div>
				</div>
			</div>
		</div>
		<nav>
			<ul>
				<li><a href="javascript:;" onClick={() => {props.onOpenArticle('intro')}}>About</a></li>
				<li><a href="javascript:;" onClick={() => {props.onOpenArticle('work')}}>License</a></li>
				<li><a href="javascript:;" onClick={() => {props.onOpenArticle('about')}}>Privacy Policy</a></li>
				<li><a href="javascript:;" onClick={() => {props.onOpenArticle('contact')}}>Contact</a></li>
			</ul>
		</nav>
	</header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
